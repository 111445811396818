.achats {
  .badge-light {
    color: theme-color('black-light')
  }
}

.achats-item, .cart-item, .modal-cart-item {
  button:not(.btn-remove) {
    font-family: $headings-font-family;

    &:not(:disabled):hover,
    &:not(:disabled).active {
      svg {
        color: theme-color(primary);
      }
    }
  }

  .form-control {
    padding: 0 0.5rem;
    font-size: 1rem;
    line-height: 1rem;
    background-color: theme-color(white);

    @include media-breakpoint-only(md) {
      max-width: 12rem;
    }

    @include media-breakpoint-only(lg) {
      max-width: 12rem;
    }

    @include media-breakpoint-only(xl) {
      max-width: 15rem;
    }

    @include media-breakpoint-only(xxl) {
      max-width: 12rem;
    }

    @include media-breakpoint-only(xxxl) {
      max-width: 15rem;
    }

    @include media-breakpoint-only(hd) {
      max-width: 14.25rem;
    }
  }

  .description {
    font-size: $font-size-14;
  }

  .price {
    font-size: $font-size-24;
    font-family: $headings-font-family;
    color: theme-color(primary);
    white-space: nowrap;
  }

  .quantity {
    font-size: $font-size-base;
    font-family: $headings-font-family;
  }

  .quantity-max {
    font-size: $font-size-12;
    font-family: $headings-font-family;
    color: theme-color(grey-darker);
  }

  .size {
    min-height: 1.75rem;
  }

  .card-footer {
    .mobile-cart-btn {
      top: 0;
      right: 0;
      height: 100%;
      border-radius: 0 !important;
    }
  }
}

.achats-item {
  img,
  h5,
  .description,
  .price {
    cursor: pointer;
  }
}

#modal-achat {
  .form-control {
    background-color: theme-color(grey);
  }
}

#modal-cart {
  margin: auto;
  right: 0;
  position: fixed;
  bottom: 0;
  width: 100%;

  .modal-content {
    height: 100vh;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    overflow-y: auto;
  }
}

.faq {
  .menu {
    .menu-item {
      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
      }

      &:hover,
      &:focus,
      &:active,
      &:active:focus,
      &:disabled,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active:focus,
      &:not(:disabled):not(.disabled).active:focus {
        color: theme-color(black-light);
        background-color: theme-color(white);
        border: 2px solid theme-color(white);
      }
    }
  }

  .btn {
    color: theme-color(black-light);
    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      color: theme-color(black-light);
    }
  }

  .btn-link:not(:hover):focus {
    text-decoration: none;
  }
}
.commande, .order {
  img {
    cursor: pointer;
  }
  .react-date-picker .react-date-picker__wrapper {
    background-color: theme-color(white);
    padding: 0.375rem 0.75rem;
    height: 2.5rem;
  }

  .react-date-picker--open .react-date-picker__wrapper {
    border-color: theme-color(primary);
    box-shadow: 0 0 0 0.2rem rgba(175,219,131,0.3);
  }

  .react-date-picker.is-invalid .react-date-picker__wrapper {
    border-color: theme-color(red) !important;
    background-image: url($icon-invalid);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 1rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    box-shadow: none;
    padding-right: calc(1.5em + 0.75rem);
  }

  .react-date-picker--open.is-invalid .react-date-picker__wrapper {
    border-color: theme-color(red) !important;
    box-shadow: 0 0 0 0.2rem rgba(253, 37, 37, 0.3);
  }

  .react-date-picker.is-valid .react-date-picker__wrapper {
    border-color: theme-color(primary) !important;
    background-image: url($icon-valid);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 1rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    box-shadow: none;
    padding-right: calc(1.5em + 0.75rem);
  }

  .react-date-picker--open.is-valid .react-date-picker__wrapper {
    border-color: theme-color(primary) !important;
    box-shadow: 0 0 0 0.2rem rgba(175,219,131,0.3);
  }

  .react-datepicker-wrapper input:not([disabled]) {
    background-color: white;
  }
}

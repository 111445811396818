.login {
  .nav {
    flex-wrap: nowrap;
  }

  .btn {
    font-size: $font-size-20;
  }

  .btn-link {
    &:focus:not(.active),
    &:hover:not(.active) {
      svg {
        color: #7d7d7d;
      }
    }
  }

  .nav-link {
    color: theme-color(white);
    font-size: $font-size-20;

    @include media-breakpoint-down(xs) {
      font-size: $font-size-base;
      padding: 0.5rem;
    }

    &:focus:not(.active),
    &:hover:not(.active) {
      color: theme-color(grey);
      border-color: transparent;

      svg {
        color: theme-color(grey);
      }
    }

    &.active {
      color: theme-color(grey);
      background-color: transparent;
      border-color: transparent;

      svg {
        color: theme-color(grey);
      }

      &::after {
        content: '';
        display: block;
        background: url(../../../assets/icons/caret-down.svg) no-repeat;
        position: relative;
        padding: 10px;
        top: 50%;
        left: 50%;
        transform: translate(-18px, 50%); // half size of the icon
        margin-top: -20px;
      }
    }
  }

  .tab-content {
    background-color: theme-color(white);
  }

  .footer {
    font-size: $font-size-20;
  }

  img, video {
    max-width: 100%;
  }
}

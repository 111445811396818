.navbar-fixed-top {
  .navbar-text,
  .navbar-nav {
    color: theme-color(white);
  }

  .navbar-nav .nav-link {
    color: theme-color(white);
    font-family: $headings-font-family;

    &:focus,
    &:hover {
      color: theme-color(grey);
      border-color: transparent;
    }
  }

  .navbar-toggler {
    padding: 0.15rem 0.75rem;
  }

  select {
    height: inherit;
    padding: 0 0.5rem;
    font-size: $font-size-base;
  }

  .navbar-collapse {
    background-color: theme-color(primary);
  }

  button {
    &:focus:not(.active),
    &:hover:not(.active) {
      background-color: theme-color(grey) !important;
      outline: 0;
    }
  }

  .fa-power-off:hover {
    cursor: pointer;
    color: theme-color(grey);
  }
}

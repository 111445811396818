@import 'react-date-picker/dist/DatePicker.css';
@import 'react-calendar/dist/Calendar.css';

.react-datepicker-popper {
  z-index: 1000;

  div.react-datepicker {
    div[aria-selected='true'] {
      background-color: theme-color-bg(primary);

      &.react-datepicker__day--disabled {
        color: theme-color(theme-primary);
        background-color: red;
      }
    }
  }
}

.order {
  .react-datepicker-wrapper input:not(.is-invalid) {
    border: none;
    padding: 0.375rem 0.75rem;
  }

  .react-datepicker__triangle {
    left: -5% !important;
  }
}

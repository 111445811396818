div {
    &.container-fluid {
        &.header {
            //max-height: 177px;
            overflow: hidden;

            > div {
                max-height: inherit;

                //> div {
                //    max-height: inherit;
                //    overflow-y: auto;
                //}
            }
        }
    }

    & h1.title {
        margin: 15px 0;
    }
}

div#layout-header {
    .info {
        .title { }
        .content {
            max-height: 150px;
            overflow: auto;
            margin-bottom: $grid-gutter-width/2;
        }
    }
}

@each $type, $values in $theme-colors {
    div.box {
        &[class~=#{$type}] {
            .header {
                @include media-breakpoint-up(md) {
                    background-color: theme-color-bg($type);
                    color: theme-color($type);
                    padding: 0.5rem;
                }


                a, button {
                    color: theme-color-bg($type);
                }

                .btn {
                    background-color: white;
                }
            }
        }

        &.#{$type} {
            > div {
                > div.menu {
                    background-color: theme-color-bg($type);

                    & .nav-item .nav-link {
                        color: theme-color($type);
                    }
                }
            }
        }
    }
}

div.box {
    .header {
        @include media-breakpoint-down(md) {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
        }
    }

    .content {
        @include media-breakpoint-down(md) {
            padding: 0.5rem !important;
        }
    }
}

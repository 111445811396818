.counters {
    & .menu-sub-item {
        border-radius: 0.4rem;

        &:not(.active) {
            color: grey!important;
        }
    }
    //& .nav:not(.nav-underline):not(.nav-tabs) .nav-link:not(.active) {
    //    & .menu-sub-item {
    //        color: grey;
    //    }
    //}
}
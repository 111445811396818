@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';

$theme-colors: () !default;

// Colors
$theme-colors: map-merge(
  (
    white: #fff,
    white-dark: #f6f0ec,
    black: #000000,
    black-light: #2c2c2c,
    blue: #528fe3,
    blue-light: #d4e3f8,
    grey: #efefef,
    grey-hover: #e6e6e6,
    grey-light: #f5f5f5,
    grey-dark: #d0d0d0,
    grey-darker: #a3a3a3,
    grey-darkest: #495057,
    primary: #afdb83,
    primary-hover: #9cd265,
    primary-dark: #6eba21,
    green: #72d55a,
    green-dark: #6e8d4f,
    green-darkest: #42651f,
    red: #ff3333,
    red-light: #e35252,
    red-lightest: #f55656,
    red-dark: #e26161,
    red-darkest: #a82323,
    orange: #ffae40,
    orange-dark: #ff980d,
    orange-light: #efbc4b,
    yellow-base: #fcdd35,
    yellow-light: #fffade,
    purple: #c838f4,
    trans: transparent,
  ),
  $theme-colors
);

// Grid Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
  hd: 1920px,
) !default;

// Grid Containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1500px,
  hd: 1800px,
) !default;

// Scaffolding
$body-bg: theme-color(white) !default;
$body-color: theme-color(black-light) !default;

$link-color: theme-color(black-light) !default;
$link-hover-color: lighten($link-color, 25%) !default;
$link-decoration: underline !default;

// Typography
$font-family-sans-serif: 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
  sans-serif !default;
$headings-font-family: 'Open Sans Bold', 'Helvetica Neue', Helvetica, Arial,
  sans-serif !default;
$headings-font-weight: 700 !default;

$font-size-base: 1rem !default; //16px
$h1-font-size: ($font-size-base * 1.5) !default; //32px
$h2-font-size: ($font-size-base * 1.3) !default; //24px
$h3-font-size: ($font-size-base * 1.2) !default; //20px
$h3-font-size: ($font-size-base) !default; //16px
$h5-font-size: ($font-size-base) !default; //16px

// Custom Typography
$font-size-8: 0.5rem; //8px
$font-size-10: 0.625rem; //10px
$font-size-12: 0.75rem; //12px
$font-size-14: 0.9rem; //14px
$font-size-20: 1.25rem; //20px
$font-size-24: 1.5rem; //24px

// Buttons
$btn-link-color: theme-color(red) !default;
$btn-link-hover-color: theme-color(red) !default;
$btn-font-size-lg: $font-size-base;
$btn-link-disabled-color: theme-color(red) !default;

// Forms
$input-bg: theme-color(grey-light) !default;
$input-border-color: theme-color(grey-light) !default;
$input-border-radius: 0rem !default;
$input-focus-border-color: theme-color(primary) !default;
$input-border-width: 0.1rem !default;
$input-placeholder-color: theme-color(grey-darker) !default;
$input-focus-box-shadow: 0 0 0 0.25rem lighten(theme-color(primary), 25%) !default;

$input-font-size: 1.25rem !default;
$input-padding-x: 1.75rem !default;

// Spacers
$spacer: 1rem !default; //16px
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  //4px
  2:
    (
      $spacer * 0.5,
    ),
  //8px
  3: $spacer,
  //16px
  4:
    (
      $spacer * 1.5,
    ),
  //24px
  5:
    (
      $spacer * 3,
    ),
  //48px
  6:
    (
      $spacer * 4,
    ),
  //64px
  7:
    (
      $spacer * 5,
    ),
  //80px
  8:
    (
      $spacer * 6.25,
    ),
  //100px
  9:
    (
      $spacer * 7.5,
    ),
  //120px
  10:
    (
      $spacer * 9.375,
    )
    //150px,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);

@import '~bootstrap/scss/bootstrap';

@import 'assets/scss/_functions';

/***************************************************
----------------- GENERIC STYLES -------------------
***************************************************/
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'),
    url(./assets/fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans Bold';
  src: local('Open Sans Bold'),
    url(./assets/fonts/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans Semi Bold';
  src: local('Open Sans Semi Bold'),
    url(./assets/fonts/OpenSans-SemiBold.ttf) format('truetype');
}

html,
body,
#root,
.App {
  height: 100%;
  font-size: $font-size-14;
}

.App {
  > div:not(:last-child) {
    padding-bottom: 3rem;

    &:not(.login) {
      height: 100%;
    }
  }
}

a,
.btn-link {
  cursor: pointer;
}

hr {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.btn .badge,
.badge {
  top: 0;
  color: white;
  font-size: smaller;
  //border-radius: 50%;
  //padding: 0.3em 0.5em;
}

.mh {
  min-height: 40px;
  line-height: 32px;
  vertical-align: middle;
}

.unselectable,
input[type='checkbox'] + label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .print-block {
    display: block;
  }
  .box {
    page-break-inside: avoid;
  }
  h1 {
    margin-top: 100px;
  }
  .logo {
    position: fixed;
  }
}

.ms-auto {
  margin-left: auto;
}

/***************************************************
----------------- LAYOUT STYLES -------------------
***************************************************/
.wrapper {
  min-height: 100vh;
  //padding-bottom: 85px;

  //@include media-breakpoint-only(xl) {
  //  padding-bottom: 110px;
  //}
  //
  //@include media-breakpoint-only(lg) {
  //  padding-bottom: 150px;
  //}
  //
  //@include media-breakpoint-only(md) {
  //  padding-bottom: 150px;
  //}
  //
  //@include media-breakpoint-only(sm) {
  //  padding-bottom: 150px;
  //}
  //
  //@include media-breakpoint-down(xs) {
  //  padding-bottom: 220px;
  //}
}

.scrolltop {
  position: fixed;
  bottom: 20px;
  z-index: 9999;
  cursor: pointer;
  animation: fadeIn 0.5s;
  transition: opacity 0.5s;
  right: $grid-gutter-width/2;

  &.full {
    width: 100%;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/***************************************************
----------------- ALERT STYLES -------------------
***************************************************/
.alert-dismissible {
  padding-right: 2rem;

  .close {
    padding: 0.3rem 0.6rem;
  }
}

/***************************************************
----------------- NAVIGATION STYLES -------------------
***************************************************/

.nav:not(.nav-underline):not(.nav-tabs) {
  .nav-link {
    color: theme-color(white);

    &:focus:not(.active),
    &:hover:not(.active) {
      color: theme-color(grey);
      border-color: transparent;

      svg {
        color: theme-color(grey);
      }
    }

    &.active {
      color: theme-color(grey);
      background-color: transparent;
      border-color: transparent;

      svg {
        color: theme-color(grey);
      }
    }

    &.disabled {
      color: theme-color(white);
      pointer-events: inherit;
      cursor: not-allowed;
      border: 2px solid transparent !important;
    }
  }
}
.tabs-underline,
.nav-underline {
  margin-bottom: $grid-gutter-width/2;

  .nav-item {
    color: $black;
    background-color: transparent;

    &.active,
    > a.active {
      color: $black;
      background-color: transparent;
      border: none;
      border-bottom: 4px solid theme-color(primary);
    }
  }
}

.menu {
  button:not(.dropdown-item) {
    padding: 0.5rem 2.5rem 0.5rem 1.5rem;
  }

  .show .dropdown-toggle {
    color: white;

    &:focus {
      box-shadow: none;
    }

    &:after {
      position: absolute;
      font-family: 'Font Awesome 5 Free';
      content: '\f077';
      font-weight: 700;
      border: none;
    }
  }

  .dropdown-toggle::after {
    position: absolute;
    font-family: 'Font Awesome 5 Free';
    content: '\f078';
    font-weight: 700;
    border: none;
  }

  .dropdown-item:hover {
    color: white;
    background-color: theme-color(primary);
  }

  .menu-item,
  .dropdown-toggle {
    border-radius: $border-radius;
    padding: 0.5rem 1rem;
    font-family: $headings-font-family;
    border: 2px solid transparent;
    margin-right: $mark-padding;
  }

  a.menu-item,
  .dropdown-toggle:not(.not-active) {
    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      color: white;
      background-color: transparent;
      border: 2px solid theme-color(white);

      &.not-active {
        border-color: transparent;
      }
    }
  }

  .nav-link {
    &:focus:not(.active),
    &:hover:not(.active),
    &.active {
      svg {
        color: theme-color(white);
      }
    }
  }
}

.nav-item {
  .menu-sub-item {
    padding: 0.375rem 1rem;
    border-radius: 10rem;
    font-family: $headings-font-family;
    color: theme-color(black-light);

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      background-color: theme-color(white);
      color: theme-color(black-light);
    }
  }
}

/***************************************************
----------------- TYPOGRAPHY STYLES -------------------
***************************************************/
.ff-bold {
  font-family: $headings-font-family;
}

.fs-24 {
  font-size: $font-size-24;
}

.fs-20 {
  font-size: $font-size-20;
}

.fs-16 {
  font-size: $font-size-base;
}

.fs-14 {
  font-size: $font-size-14;
}

.fs-12 {
  font-size: $font-size-12;
}

.fs-10 {
  font-size: $font-size-10;
}

.fs-8 {
  font-size: $font-size-8;
}

.text-no-underline {
  text-decoration: none;
}

.opacity-100 {
  opacity: 100%;
}

.opacity-75 {
  opacity: 75%;
}

.opacity-50 {
  opacity: 50%;
}

.opacity-25 {
  opacity: 25%;
}

/***************************************************
----------------- BUTTONS STYLES -------------------
***************************************************/
.btn {
  color: theme-color(white);
  border: 2px solid transparent;

  &:hover,
  &:focus,
  &:active,
  &:active:focus,
  &:disabled,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    color: theme-color(white);
    box-shadow: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.btn-white {
  color: theme-color(primary);

  &:hover,
  &:focus,
  &:active,
  &:active:focus,
  &:disabled,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    color: theme-color(primary);
    box-shadow: none;
  }
}

.btn-rounded,
.btn-rounded-sm {
  border-radius: $border-radius;
  padding: 0.25rem 1rem;
}

.btn-circle {
  border-radius: 50%;
  padding: 0.625rem;
}

.btn-square {
  padding: 0.625rem;
}

.btn-remove {
  &:not(:disabled):hover,
  &:not(:disabled).active {
    span {
      color: theme-color(grey-darkest);
      text-decoration: underline;
    }
    svg {
      color: theme-color(grey-darkest);
    }
  }
}

.btn-trans:not(.accordion-link) {
  &:not(:disabled):hover,
  &:not(:disabled).active {
    svg {
      color: theme-color(primary-hover);
    }
  }
}

.btn-white-purple {
  @extend .btn-white;

  color: theme-color(purple);

  &:hover,
  &:focus,
  &:active,
  &:active:focus,
  &:disabled,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    color: theme-color(purple);
    box-shadow: none;
  }
}

/***************************************************
----------------- PICTO STYLES -------------------
***************************************************/

.picto {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  height: 30px;
}

.picto-sm {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25px;
  height: 25px;
}

.picto-xs {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
}

.margin-icon {
  margin-left: 2px;
  margin-right: 2px;
}

.padding-icon {
  padding-left: 11px;
  padding-right: 11px;
}

.padding-icon-xl {
  padding-left: 10px;
  padding-right: 10px;
}

/***************************************************
----------------- BORDER STYLES -------------------
***************************************************/
.border {
  border-width: 2px !important;
}

.border-xl {
  border-width: 3px !important;
}

.circle {
  border-radius: 50%;
}

.rounded-xl {
  border-radius: 1.5rem;
}

.rounded-xxl {
  border-radius: 10rem;
  padding: 0.5rem 2rem;

  @include media-breakpoint-down(sm) {
    border-radius: 1rem;
  }
}

.rounded-top-xl {
  border-top-right-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
}

.rounded-bottom-xl {
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

/***************************************************
----------------- TABLE STYLES -------------------
***************************************************/
.table {
  text-align: center;

  thead {
    tr {
      background-color: theme-color(grey);
    }

    th {
      border: none;

      span {
        justify-content: center;
      }

      svg {
        &:hover,
        &:focus,
        &:active,
        &.active {
          color: theme-color(primary);
          cursor: pointer;
        }
      }
    }
  }

  td {
    vertical-align: middle;
    border-top: 0;
    border-bottom: 1px solid theme-color(grey);

    .btn-link {
      font-family: $headings-font-family;
    }
  }

  .alert {
    text-align: left;
  }
}

/***************************************************
----------------- PAGINATION STYLES -------------------
***************************************************/
.page-link {
  border: 0;
  background-color: theme-color(grey);
  margin: 0 0.1rem;

  &:first-child,
  &:last-child {
    background-color: transparent;
  }

  &:hover {
    color: theme-color(white);
    background-color: theme-color(primary);
    border: 0;
  }

  &:focus,
  &:active {
    box-shadow: none;
  }
}

.page-item:first-child .page-link {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/***************************************************
----------------- FORMS STYLES -------------------
***************************************************/
form:not(.form-inline) {
  .form-label {
    margin-bottom: 0.2rem;
  }
}

select.form-control,
input.form-control,
textarea.form-control,
.input-group-prepend {
  font-size: $font-size-base;
  border-radius: 0.25rem;
  border-width: 0.125rem;

  &:not(textarea) {
    height: 2.5rem;
  }

  &:not(.input-group-prepend) {
    padding: 0.375rem 0.75rem;
  }

  &:focus {
    border-color: theme-color(primary);
    box-shadow: 0 0 0 0.2rem rgba(175, 219, 131, 0.3);
  }

  &.is-invalid {
    border-color: theme-color(red);
    background-position: right calc(0.375em + 1rem) center;
    padding: 0.375rem 0.75rem;
    box-shadow: none;

    &:focus {
      border-color: theme-color(red);
      box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.3);
    }

    &.xs {
      background-position: right calc(0.375em + 0.5rem) center;
    }
  }

  &.is-valid {
    border-color: theme-color(primary);
    background-position: right calc(0.375em + 1rem) center;
    padding: 0.375rem 0.75rem;
    box-shadow: none;

    &:focus {
      border-color: theme-color(primary);
      box-shadow: 0 0 0 0.2rem rgba(175, 219, 131, 0.3);
    }

    &.xs {
      background-position: right calc(0.375em + 0.5rem) center;
    }
  }
}

.input-group-prepend {
  .input-group-text {
    border-radius: 0 8px 8px 0 !important;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

input[type='checkbox'] + label {
  cursor: pointer;
}

#magasinsSelect option:first-of-type {
  color: theme-color(grey-darkest);
}

.form-group {
  margin-bottom: 0;

  .dropdown-item {
    &.active,
    &:active,
    &:hover,
    &:focus {
      color: theme-color(white);
      text-decoration: none;
      background-color: theme-color(primary);
    }
  }
}

textarea.form-control {
  font-size: $font-size-base;
  padding: 0.375rem 0.75rem;

  &:focus {
    border-color: theme-color(primary);
    box-shadow: 0 0 0 0.2rem rgba(175, 219, 131, 0.3);
  }

  &.is-invalid {
    border-color: theme-color(red);
    background-position: right calc(0.375em + 1rem) top 0.375rem;
    box-shadow: none;

    &:focus {
      border-color: theme-color(red);
      box-shadow: 0 0 0 0.2rem rgba(253, 37, 37, 0.3);
    }
  }

  &.is-valid {
    border-color: theme-color(primary);
    background-position: right calc(0.375em + 1rem) top 0.375rem;
    box-shadow: none;

    &:focus {
      border-color: theme-color(primary);
      box-shadow: 0 0 0 0.2rem rgba(175, 219, 131, 0.3);
    }
  }
}

.filter {
  button,
  a[role='button'] {
    font-family: $headings-font-family;
    font-size: $font-size-base;

    &:disabled,
    &.btn-circle {
      svg {
        &:hover,
        &:focus,
        &:active,
        &.active {
          color: inherit;
          cursor: inherit;
        }
      }
    }
  }

  svg:not(.add) {
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: theme-color(primary);
      cursor: pointer;
    }
  }
}

.rbt-input-main.form-control {
  background-color: theme-color(white);
}

.rbt-aux {
  align-items: center;
  display: flex;
  bottom: 0;
  justify-content: center;
  position: absolute;
  right: 1%;
  top: 0;
  width: 34px;
  color: theme-color(primary);

  .rbt-close {
    cursor: pointer;
  }
}

$icon-valid: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e";
$icon-invalid: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e";

.rbt.is-invalid .rbt-input {
  border-color: theme-color(red) !important;
  background-image: url($icon-invalid);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 1rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  box-shadow: none;
  padding-right: calc(1.5em + 0.75rem);
}

.rbt.is-invalid .rbt-input:focus {
  border-color: theme-color(red) !important;
  box-shadow: 0 0 0 0.2rem rgba(253, 37, 37, 0.3);
}

.rbt.is-valid .rbt-input {
  border-color: theme-color(primary) !important;
  background-image: url($icon-valid);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 1rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  box-shadow: none;
  padding-right: calc(1.5em + 0.75rem);
}

.rbt.is-valid .rbt-input:focus {
  border-color: theme-color(primary) !important;
  box-shadow: 0 0 0 0.2rem rgba(175, 219, 131, 0.3);
}

/***************************************************
----------------- MODAL STYLES -------------------
***************************************************/
.modal {
  z-index: 9999;
}

.modal-no-header .close {
  padding: 1rem 1rem;
  margin: 0 0 -1rem auto;
}

.modal-footer {
  border-top: none;

  button {
    width: 8rem;
  }
}

.modal-content {
  border: none;
  border-radius: $border-radius;
  overflow: hidden;

  .modal-body img {
    display: block;
    max-height: 100%;
    max-width: 100%;
  }
}

/***************************************************
----------------- DATE PICKER STYLES -------------------
***************************************************/
.react-date-picker {
  font-size: 1rem;

  &.form-control {
    border-radius: $border-radius;
    color: $input-placeholder-color;
  }

  &__wrapper {
    border: none;
    padding-left: 0.375rem;
  }
}

.react-date-picker-OLD {
  .react-date-picker__wrapper {
    align-items: center;
    padding: 0.375rem;
    color: theme-color(grey-darkest);
    background-color: theme-color(grey);
    border: 2px solid theme-color(grey) !important;
    border-radius: $border-radius;
    height: 2.25rem;
  }

  .react-calendar {
    border: 1px solid theme-color(grey);
  }

  .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
  .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: theme-color(primary) !important;
  }

  button:focus,
  input:focus {
    outline: 0;
  }

  input {
    color: theme-color(grey-darkest);
  }

  .react-date-picker__inputGroup__input:invalid {
    background: theme-color(primary);
  }

  .react-calendar__navigation button {
    font-family: $headings-font-family;
    text-transform: capitalize;
  }

  .react-calendar__navigation button:not(:disabled) {
    color: theme-color(primary);
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: theme-color(grey-hover);
  }

  .react-calendar__navigation button[disabled] {
    background-color: theme-color(grey);
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: capitalize;
    font-family: $headings-font-family;
    font-size: 0.75em;
  }

  abbr[title],
  abbr[data-original-title] {
    text-decoration: none;
  }

  button:not(.react-calendar__year-view__months__month):not(.react-calendar__decade-view__years__year):not(.react-calendar__century-view__decades__decade) {
    &.react-calendar__tile:not(:disabled),
    &.react-calendar__tile:enabled:hover,
    &.react-calendar__tile:enabled:focus {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 0.8rem !important;
      max-height: 0.8rem;
      padding: 1rem;
      margin: 0.55rem;
      border-radius: 50%;
      color: theme-color(grey-darkest);
      background-color: transparent;
    }

    &.react-calendar__tile:enabled:hover,
    &.react-calendar__tile:enabled:focus {
      background-color: theme-color(grey-hover);
    }

    &.react-calendar__tile--active:not(:disabled) {
      background: theme-color(primary) !important;
    }

    &.react-calendar__tile--active:enabled:hover,
    &.react-calendar__tile--active:enabled:focus {
      background: theme-color(primary-hover) !important;
    }
  }

  .react-calendar__tile--hasActive:not(:disabled) {
    background: theme-color(primary) !important;
  }

  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: theme-color(primary-hover) !important;
  }

  .react-calendar__tile--now {
    background: theme-color(orange);
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: theme-color(orange-dark);
  }

  .react-calendar__tile--active:disabled {
    color: rgba(16, 16, 16, 0.3);
  }

  .react-calendar__tile:disabled {
    background-color: theme-color(grey);
  }

  .react-calendar__month-view__days__day--weekend {
    color: theme-color(red) !important;
  }

  ::-moz-selection {
    color: theme-color(grey-darkest);
    background: theme-color(primary);
  }

  ::selection {
    color: theme-color(grey-darkest);
    background: theme-color(primary);
  }
}

.react-date-picker--open .react-date-picker__wrapper {
  color: theme-color(grey-darkest);
  //border: 2px solid theme-color(primary) !important;
  //border-radius: 1.5rem;
  outline: 0;
}

/***************************************************
----------------- ANIMATION STYLES -------------------
***************************************************/
.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms, transform 500ms;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 500ms linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms linear;
}

/***************************************************
----------------- TOOLTIPS STYLES -------------------
***************************************************/
.tooltip {
  z-index: 9999;
}

.tooltip-inner {
  font-size: $font-size-12;
  background: theme-color(grey-darkest);
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: theme-color(grey-darkest);
}

/***************************************************
----------------- WIDTHS STYLES -------------------
***************************************************/

.w-fit-content {
  width: fit-content;
}

/***************************************************
----------------- COMPONENTS STYLES -------------------
***************************************************/
@import './components/ui/header/Header.scss';
@import './components/ui/card/Card.scss';
@import './components/ui/side_menu/SideMenu.scss';
@import './components/ui/footer/Footer.scss';
@import './components/pages/login/Login.scss';
@import './components/pages/achats/Achats.scss';
@import './components/pages/catalogue/Catalogue.scss';
@import './components/pages/magasin/Magasin.scss';
@import './components/pages/faq/Faq.scss';
@import './components/pages/commande/Commande.scss';
@import './components/pages/pointages/Pointages.scss';
@import './components/pages/clients/Clients.scss';
@import './components/pages/magasin/perimetre';

@import 'assets/scss/_custom';

.catalogue-item {
  .content {
    font-size: $font-size-14;

    @include media-breakpoint-down(sm) {
      font-size: $font-size-base;
    }

    @include media-breakpoint-up(xxl) {
      font-size: $font-size-base;
    }

    @include media-breakpoint-up(hd) {
      font-size: $font-size-14;
    }
  }

  button,
  a[role='button'] {
    font-family: $headings-font-family;
    font-size: $font-size-14;
  }

  .btn-rounded-sm {
    padding: 0.25rem 0.5rem;
  }

  .form-control {
    width: 4.5rem;
    height: 1.75rem;
    padding: 0 0.5rem;
    font-size: 1rem;
    line-height: 1rem;
    background-color: theme-color(white);
  }

  img,
  .libelle {
    cursor: pointer;
  }

  .reference,
  .libelle,
  .category {
    font-family: $headings-font-family;
  }

  .price {
    font-size: $font-size-base;
    font-family: $headings-font-family;
    color: theme-color(primary);
  }

  .prices {
    @include media-breakpoint-down(xs) {
      font-size: $font-size-14;
    }
  }
}

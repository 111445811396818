/********/
/* CARD */
/********/

.card {
    &-horizontal {
        display: flex;
        flex: 1 1 auto;

        > .card-header {
            display: flex;
            border-bottom: none;
            border-right: 1px solid rgba(0, 0, 0, 0.125);
            padding: 0.75rem;
        }

        > .card-body {
            padding: 0.825rem;
        }
    }
    > .card-footer {
        display: flex;
        border-bottom: none;
        //border-right: 1px solid rgba(0, 0, 0, 0.125);
        padding: 0.75rem;
    }
}

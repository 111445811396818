#menu-collapse {
    & .collapse {
        & .nav-link:hover {
            color: grey;
        }
    }

    #flowerFeast a {
        background-color: $transfer-core-color;
        color: white;

        &:hover {
            background-color: darken($transfer-core-color, 20%);
            text-decoration: none;
            font-weight: normal;
        }
    }
}
.perimetres {
  .btn-danger {
    background-color: red !important;
    border-color: red !important;
  }

  #active-perimeter .bg-secondary{
    background-color: rgba(108, 117, 125, 0.17) !important
  }

  #addPerimeters {
    top: 0.5rem;
    right: 0.5rem;

    @include media-breakpoint-down(md) {
      transform: translateY(-100%);
      top: 100%;
      left: 0;
      padding: 0;
      margin: 0;
      background: transparent;
      font-size: 0.8rem;
    }
  }
}




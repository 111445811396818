.dropdown {
    & .dropdown-toggle, &.show {
        &.btn-success {
            color: theme-color(theme-primary);
            background-color: theme-color-bg(primary);
            border-color: theme-color-bg(primary);
        }
    }

    & .btn-success {
        color: theme-color(theme-primary);
        background-color: theme-color-bg(primary);
        border-color: theme-color-bg(primary);
    }

    & .dropdown-menu {
        min-width: max-content;
    }
}

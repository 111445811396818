.modal-content {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}

#modal-contact-easiware {
    padding: 2em;

    & button {
        background-color: theme-color-bg(theme-primary);
        color: theme-color(theme-primary);
        border: 0 none;
        margin-top: 15px;
        padding: 14px 24px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        white-space: normal;

        &:hover {
            opacity: 0.8;
        }
    }

    &  > div:first-of-type input,
     > div:first-of-type textarea,
     > div:first-of-type select {
        width:100%;
        height: 2.25em;
        padding: 0 0.625em;
        border: 1px solid #e4eaef;
        border-radius: 4px;
    }

    & > div:first-of-type textarea {
        min-height: 190px;
    }

    & [id^="easi_labelspan"] {
        display: block;
        font-size: 1.125em;
        color: black;
    }

    & > label {
        font-weight: 700;
    }

    & [id^="easi_fieldspan"] {
        color: black;
    }

    & [id^="easi_fielddiv"]:not(:last-child) {
        margin-bottom: 20px;
    }

    & [id^="easi_fielddiv_CS_MemberCode"] {
        display: none;
    }

    .mandatory {
        position: absolute;
        color: red;
    }
}
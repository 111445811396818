@import '_text';
@import '_colors';
@import '_forms';
@import 'close';

@import '_dashboard';
@import '_layout';

@import '_orders';
@import '_shop';
@import '_contactEasiware';
@import '_components';
@import 'partners';
@import '_counter';
@import '_customer';
@import '_datepicker';

@import '_dropdown';
@import '_shoppers';
@import 'menu';

.hidden {
  display: none;
}

.overflow-visible {
  overflow: visible;

  &-x {
    overflow-x: visible;
  }

  &-y {
    overflow-y: visible;
  }
}

.z-1000 {
  z-index: 1000 !important;
}

.dashboard-executions.scroll, .dashboard-transmissions.scroll {
    .table-responsive {
        overflow: auto;
        height: 500px;

        @include media-breakpoint-up(md) {
            height: 483px;
        }

        @include media-breakpoint-up(xl) {
            height: 435px;
        }

        @include media-breakpoint-up(xxl) {
            height: 267px;
        }

        thead th {
            position: sticky;
            top: 0;
            background-color: theme-color(grey);
        }
    }
}

.dashboard-executions, .dashboard-transmissions, .dashboard-sav {
    tbody tr:last-child td {
        border: none;
    }
}

.tabs-underline, .nav-underline {
    margin-bottom: $grid-gutter-width/2;

    .nav-item {
        color: $black;
        background-color: transparent;

        &.active,
        > a.active {
            color: $black;
            background-color: transparent;
            border: none;
            border-bottom: 4px solid theme-color(primary);
        }
    }
}

@include media-breakpoint-down(sm) {
    .date-picker-container {
        .modal-dialog {
            margin: 0;

            .modal-content {
                width: 100% !important;
                height: 100% !important;

                .date-picker-control {
                    margin-bottom: 320px;

                    > div:first-of-type {
                        //@extend .mb-4;
                        margin-bottom: 1.5rem;
                    }
                }
            }
        }
    }
}

.date-picker-container {
    .modal-dialog {
        .modal-content {
            .date-picker-control {
                margin-bottom: 320px;
            }
        }
    }
}

div.commande, div.order, div#summary {
    & .choix-floral .header, & .product .header {
        background-color: $monflorajet-core-color;
    }

    & .livraison .header, & .delivery .header {
        background-color: lightslategrey;
    }

    & .message .header {
        background-color: lightgray;
    }

    & .expediteur .header, & .sender .header {
        background-color: $transfer-core-color;
    }

    & .infos-prix .header, .prix .header, & .price .header {
        background-color: $transfer-core-color;
    }

    & .receiver .header {
        background-color: $executions-core-color;
    }

    & .accessories {
        & .header {
            background-color: $executions-core-color;
        }

        & .btn-add, & .btn-minus {
            background-color: $monflorajet-core-color;
            border-color: $monflorajet-core-color;
        }
    }

    & .header {
        color: white;
    }
}

div#summary {
    .receiver .content, .sender .content {
        height: 300px;
        overflow: auto;
    }
}

.bg-color-parent {
    background-color: inherit;
}

// Order entry
.accessories {
    & .card-img-overlay {
        padding-top: 80%!important;
        bottom: auto;
    }
}
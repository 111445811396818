$monflorajet-core-color: #6eba21;
$transfer-core-color: #528fe3;
$executions-core-color: orange;
$sav-core-color: $monflorajet-core-color;

$theme-primary: (
    bg:    $monflorajet-core-color,
    color: white,
    dark:  color-yiq($monflorajet-core-color),
    light: lighten($monflorajet-core-color, 20%),
) !default;

$transfer: (
    bg:    $transfer-core-color,
    color: white,
    dark:  color-yiq($transfer-core-color),
    light: lighten($transfer-core-color, 10%),
) !default;

$executions: (
    bg:    $executions-core-color,
    color: white,
    dark:  color-yiq($executions-core-color),
    light: lighten($executions-core-color, 10%),
) !default;

$sav: (
    bg:    $sav-core-color,
    color: white,
    dark:  color-yiq($sav-core-color),
    light: lighten($sav-core-color, 10%),
) !default;

$magasin: (
        bg:    $sav-core-color,
        color: white,
        dark:  color-yiq($sav-core-color),
        light: lighten($sav-core-color, 10%),
) !default;

$horaires: $magasin;
$conges: $magasin;
$ouvertures: $magasin;
$perimetres: $magasin;

$theme-colors: map-merge(
    (
        transmissions: $transfer,
        executions: $executions,
        sav: $sav,
        theme-primary: $theme-primary,
        magasin: $magasin,
        horaires: $horaires,
        conges: $conges,
        perimetres: $perimetres,
        ouvertures: $ouvertures,
    ),
    $theme-colors
);

@import "../../components/ui/modal/modalCustom";
@import "../../components/ui/button/buttons";

@each $type, $values in $theme-colors {
    .btn-outline-#{$type}, .btn-outline-#{$type}:hover, .btn-outline-#{$type}:active {
        background-color: transparent !important;
        color: theme-color($type) !important;
        border: 1px solid theme-color($type) !important;
        border-radius: 40px;
        font-weight: bold;

        * {
            color: theme-color($type);
            font-weight: bold;
        }
    }

    .btn-outline-#{$type}:hover {
        text-decoration: underline solid theme-color($type) 2px !important;
    }
}
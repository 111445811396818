.side-menu {
    z-index: 1000;
    //overflow-y: auto;
    //overflow-x: hidden;
    top: 0;
    left: 0;
    bottom: 0;
    padding-bottom: 140px;
    overflow: auto;

    .closed {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }

    .nav-link:not(.bg-primary):not(.bg-orange),
    .accordion-link {
        color: theme-color(grey-darker);
        font-size: $font-size-14;

        &:hover,
        &.active {
            background-color: transparent;
            color: theme-color(primary);
            text-decoration: underline;
            font-weight: bold;
        }
    }

    .nav-link.bg-orange {
        &:hover,
        &.active {
            background-color: theme-color(orange-dark) !important;
            color: theme-color(white);
        }
    }

    .nav-link.active.active.bg-primary {
        background-color: theme-color(primary-dark) !important;
    }

    .nav-link.bg-primary:focus:not(.active),
    .nav-link.bg-primary:hover:not(.active) {
        color: theme-color(white);
        border-color: transparent;
    }

    .nav-link.active.bg-primary svg,
    .nav-link.bg-primary:hover svg,
    .nav-link.active.bg-orange svg,
    .nav-link.bg-orange:hover svg {
        color: theme-color(white) !important;
    }

    button.bg-primary-dark:hover,
    button.bg-primary-dark:focus {
        background-color: theme-color(primary-dark) !important;
    }

    .fixed-bottom {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
    }

    table {
        font-size: 0.7rem;
        font-weight: bold;

        thead {
            border-bottom: 2px solid theme-color(grey);

            tr {
                background-color: transparent;
            }

            th {
                border-right: 2px solid theme-color(grey);
            }

            th:last-child {
                border-right: 0;
            }
        }

        td {
            border-bottom: 0;
        }

        tr > td:first-child {
            text-align: left;
        }

        tbody {
            td {
                border-right: 2px solid theme-color(grey);
            }

            tr > td:last-child {
                border-right: 0;
            }

            tr:last-child {
                border-top: 2px solid theme-color(grey);
            }
        }

        td:not(:first-child) {
            color: theme-color(red);
        }
    }

    .table-responsive {
        margin-bottom: 0.5rem;
    }

    select.form-control {
        //  padding: 0.25rem;
        width: 100%;
    }

    .fa-power-off:hover {
        cursor: pointer;
        color: theme-color(primary);
    }
}

.overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 997;
    opacity: 0;

    &.active {
        display: block;
        opacity: 1;
        position: absolute;
        background-color: #a3a3a37a;
    }
}

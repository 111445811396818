.customer {
  &:hover,
  &:focus,
  &:active,
  &:active:focus,
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    background-color: theme-color(grey) !important;
    cursor: pointer;
  }
}

.letter {
  &:hover,
  &:focus,
  &:active,
  &:active:focus,
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    background-color: theme-color(grey) !important;
    color: theme-color(primary) !important;
    cursor: pointer;
  }
}

#modal-edit {
  .rbt-input-main.form-control {
    background-color: theme-color(grey-light);
  }
}

.footer {
  &.fixed-bottom {
    width: 100%;
    bottom: 0;
    position: absolute;
  }

  a:hover,
  a.active {
    svg {
      color: theme-color(primary-hover);
    }
  }
}

select.form-control.is-valid {
    padding-right: 0.75rem!important;
}

.form-control {
    &[readonly] {
        opacity: 0.5;
        pointer-events: none;
    }
}

#order_form {
    input, textarea, select {
        margin-bottom: 10px;

        option:disabled {
            color: lightgray;
        }

        &:not([type=number]):not([disabled]) {
            background-color: white;
        }
    }
}

td .form-check .form-check-input {
    position: relative;
}

.form-check {
    &:not(.negative) input[type="radio"] {
        accent-color: green;
    }

    &.negative {
        input[type="radio"] {
            accent-color: darkred;
        }
    }
}


.horaires {
  h3 {
    line-height: 2.25rem;
  }

  .table thead th {
    background-color: theme-color(grey);
  }

  .table tr td,
  .table th {
    padding: 1rem 0;
  }

  .table tr > td:not(.table-alert):first-child {
    font-family: $headings-font-family;
  }

  .table tr > td:not(:first-child) {
    color: theme-color(grey-darker);
  }

  .table tr > td.table-alert {
    background-color: theme-color(white);
    border-bottom: none;
  }

  .table thead th svg:hover {
    color: theme-color(grey-dark);
    cursor: initial;
  }

  .table td:nth-child(even) {
    background-color: theme-color(grey);
  }
}

.conges, .ouvertures {
  .table {
    background-color: theme-color(grey-light);
    text-align: left;

    th span {
      justify-content: start;
    }
  }

  th, td {
    padding: 0.75rem 2rem;
  }
}

.ouvertures {
  .header {
    padding: 1.375rem;
  }
}

.perimetres {
  .table {
    tbody td:first-child {
      text-align: left;
    }
  }
}

.charts {
  width: 100%;
  height: 45vh;

  & canvas {
    width: 100%!important;
  }
}

#modal-horaires {
  .modal-footer button {
    width: inherit;
  }
  
  .embed-responsive {
    height: 720px;
    width: 585px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.partner {
    .card-body .description {
        max-height: 300px;
        overflow: auto;
    }

    .card-footer {
        padding: 0;
    }
}

$prefix: 'bs';

// scss-docs-start focus-ring-variables
$focus-ring-width: 0.25rem !default;
$focus-ring-opacity: 0.25 !default;
$focus-ring-color: rgba($primary, $focus-ring-opacity) !default;
$focus-ring-blur: 0 !default;
$focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color !default;
// scss-docs-end focus-ring-variables

// scss-docs-start close-variables
$btn-close-width: 1em !default;
$btn-close-height: $btn-close-width !default;
$btn-close-padding-x: 0.25em !default;
$btn-close-padding-y: $btn-close-padding-x !default;
$btn-close-color: $black !default;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>") !default;
$btn-close-focus-shadow: $focus-ring-box-shadow !default;
$btn-close-opacity: 0.5 !default;
$btn-close-hover-opacity: 0.75 !default;
$btn-close-focus-opacity: 1 !default;
$btn-close-disabled-opacity: 0.25 !default;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(200%) !default;
// scss-docs-end close-variables

.btn-close {
  // scss-docs-start close-css-vars
  --#{$prefix}btn-close-color: #{$btn-close-color};
  --#{$prefix}btn-close-bg: #{escape-svg($btn-close-bg)};
  --#{$prefix}btn-close-opacity: #{$btn-close-opacity};
  --#{$prefix}btn-close-hover-opacity: #{$btn-close-hover-opacity};
  --#{$prefix}btn-close-focus-shadow: #{$btn-close-focus-shadow};
  --#{$prefix}btn-close-focus-opacity: #{$btn-close-focus-opacity};
  --#{$prefix}btn-close-disabled-opacity: #{$btn-close-disabled-opacity};
  --#{$prefix}btn-close-white-filter: #{$btn-close-white-filter};
  // scss-docs-end close-css-vars

  box-sizing: content-box;
  width: $btn-close-width;
  height: $btn-close-height;
  padding: $btn-close-padding-y $btn-close-padding-x;
  color: var(--#{$prefix}btn-close-color);
  background: transparent var(--#{$prefix}btn-close-bg) center /
    $btn-close-width auto no-repeat; // include transparent for button elements
  border: 0; // for button elements
  @include border-radius();
  opacity: var(--#{$prefix}btn-close-opacity);

  // Override <a>'s hover style
  &:hover {
    color: var(--#{$prefix}btn-close-color);
    text-decoration: none;
    opacity: var(--#{$prefix}btn-close-hover-opacity);
  }

  &:focus {
    outline: 0;
    box-shadow: var(--#{$prefix}btn-close-focus-shadow);
    opacity: var(--#{$prefix}btn-close-focus-opacity);
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    user-select: none;
    opacity: var(--#{$prefix}btn-close-disabled-opacity);
  }
}

@mixin btn-close-white() {
  filter: var(--#{$prefix}btn-close-white-filter);
}

.btn-close-white {
  @include btn-close-white();
}
